@import "../../App.sass"

.contact-container
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  .contact-item
    display: flex
    align-items: center
    margin: 10px 0px

    .contactSlide-icon
      font-size: 90px
      padding-bottom: 20px
      margin-right: 10px

      @include media(">tablet")
        font-size: 70px
        margin-right: 5px

      @include media(">phone", "<=tablet")
        font-size: 45px
        padding-bottom: 10px

      @include media("<=phone")
        font-size: 30px
        padding-bottom: 5px

    .contact-text
      -webkit-text-fill-color: transparent
      -webkit-text-stroke-color: var(--fontColour)
      -webkit-text-stroke-width: 0.015em
      font-size: 90px
      transition: 0.5s

      &:hover
        cursor: pointer
        -webkit-text-stroke-color: transparent

      &-animate
        position: relative

      &-animate::before
        position: absolute
        top: -1px
        left: 0
        max-width: 0
        width: 100%
        overflow: hidden
        white-space: nowrap
        content: attr(data-text)
        transition: max-width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1)
        -webkit-text-fill-color: var(--fontColour)

      &:hover::before
        max-width: 100%

      @include media(">tablet")
        font-size: 70px

      @include media(">phone", "<=tablet")
        font-size: 50px

      @include media("<=phone")
        font-size: 28px
        -webkit-text-fill-color: var(--fontColour)

  .contact-footer
    position: absolute
    bottom: 30px
    font-size: 16px
