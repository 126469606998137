@import "../../../App.sass"

.container
  width: 40vw
  height: 60vh
  display: flex
  flex-direction: column
  justify-content: center

  @include media("<=tablet")
    width: 90%

.title-container
  height: 120px
  position: relative
  display: flex
  flex-direction: column
  justify-content: center

  .number
    position: absolute
    left: -100px
    color: var(--white100)
    opacity: 0.1

    @include media("<=tablet")
      display: none

  .timeframe
    margin-left: 120px
    color: var(--white100)
    opacity: 0.5

    @include media("<=tablet")
      display: none

.card, .card-expanded
  position: relative
.card
  width: 70%
  height: 20%
  &-expanded
    width: 100%
    height: 80%

.row-top, .row-top-expanded
  display: flex
.row-top
  height: 100%
  &-expanded
    height: 30%
    margin-bottom: 8px

.top-left, .top-left-expanded
  background-color: var(--accentPrimary)
  color: var(--white500)
  display: flex
  justify-content: center
  align-items: center
  padding: 12px
  border-radius: 8px
  font-size: 1.2vw

  @include media("<=tablet")
    font-size: 3vw

.top-left
  width: 100%
  filter: drop-shadow(8px 8px var(--accentThirdary))
  transition: filter 0.3s
  &-expanded
    width: 70%
    margin-right: 4px

.top-right
  background-color: var(--accentSecondary)
  width: 30%
  margin-left: 4px
  padding: 12px
  border-radius: 8px
  overflow: hidden
  display: flex
  justify-content: space-evenly
  align-items: center
  flex-wrap: wrap
  gap: 4px

  img
    width: 20%

    @include media("<=phone")
      width: 25%

.row-bot
  background-color: var(--accentThirdary)
  color: var(--white500)
  height: 70%
  padding: 12px
  border-radius: 8px
  overflow: hidden
  display: flex
  gap: 12px

  @include media("<tablet")
    height: 50%

  .images
    width: 50%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 12px

    @include media("<=laptop")
      display: none

    .project-img
      width: 100%
      border-radius: 4px

    .caption
      font-size: 0.6rem

  .paras
    width: 50%
    font-size: 0.6rem
    display: flex
    flex-direction: column
    justify-content: center
    gap: 12px
    font-size: 0.8vw

    @include media("<=tablet")
      width: 100%
      font-size: 2.6vw

    .links
      display: flex
      gap: 12px

      .link-item
        text-decoration: none
        padding: 0.4rem 0.8rem
        background-color: var(--accentPrimary)
        color: var(--white500)
        border-radius: 8px
        transition: 0.3s all

        &:hover
          scale: 1.05

.expand-button, .collapse-button
  font-size: 24px
  position: absolute
  transition: scale 0.3s
  &:hover
    cursor: pointer
    scale: 1.1

.expand-button
  right: -10px
  bottom: -16px

.collapse-button
  right: -12px
  bottom: -24px

  @include media("<=tablet")
    bottom: 60px
