@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap")

:root
    font-size: 20px

    --bgColour100: #000000
    --bgColour200: #000000
    --bgColour300: #000000
    --bgColour400: #000000
    --bgColour: #000000
    --bgColour600: #000000
    --bgColour700: #000000
    --bgColour800: #000000
    --bgColour900: #000000

    --fontColour100: #000000
    --fontColour200: #000000
    --fontColour300: #000000
    --fontColour400: #000000
    --fontColour: #000000
    --fontColour600: #000000
    --fontColour700: #000000
    --fontColour800: #000000
    --fontColour900: #000000

    --glassColour: rgba(0, 0, 0, 0)
    --glassLight: rgba(255, 255, 255, 0.05)
    --glassDark: rgba(0, 0, 0, 0.05)

    --maroonPrimary: #a00045
    --maroonSecondary: #8E2F58
    --maroonThirdary: #E65192

    --black100: #515151
    --black200: #464646
    --black300: #3b3b3b
    --black400: #303030
    --black500: #252525
    --black600: #232323
    --black700: #212121
    --black800: #1f1f1f
    --black900: #1e1e1e

    --white100: #bdbdbd
    --white200: #c9c9c9
    --white300: #d4d4d4
    --white400: #e0e0e0
    --white500: #ECECEC
    --white600: #ededed
    --white700: #eeeeee
    --white800: #efefef
    --white900: #f0f0f0

body
    font-family: "Josefin Sans", sans-serif
    background-color: var(--bgColour)
    color: var(--fontColour)

body.light-mode
    --bgColour100: var(--white100)
    --bgColour200: var(--white200)
    --bgColour300: var(--white300)
    --bgColour400: var(--white400)
    --bgColour: var(--white500)
    --bgColour600: var(--white600)
    --bgColour700: var(--white700)
    --bgColour800: var(--white800)
    --bgColour900: var(--white900)

    --fontColour100: var(--black100)
    --fontColour200: var(--black200)
    --fontColour300: var(--black300)
    --fontColour400: var(--black400)
    --fontColour: var(--black500)
    --fontColour600: var(--black600)
    --fontColour700: var(--black700)
    --fontColour800: var(--black800)
    --fontColour900: var(--black900)

    --glassColour: var(--glassLight)

    --accentPrimary: var(--maroonPrimary)
    --accentSecondary: var(--maroonSecondary)
    --accentThirdary: var(--maroonThirdary)

body.dark-mode
    --bgColour100: var(--black100)
    --bgColour200: var(--black200)
    --bgColour300: var(--black300)
    --bgColour400: var(--black400)
    --bgColour: var(--black500)
    --bgColour600: var(--black600)
    --bgColour700: var(--black700)
    --bgColour800: var(--black800)
    --bgColour900: var(--black900)

    --fontColour100: var(--white100)
    --fontColour200: var(--white200)
    --fontColour300: var(--white300)
    --fontColour400: var(--white400)
    --fontColour: var(--white500)
    --fontColour600: var(--white600)
    --fontColour700: var(--white700)
    --fontColour800: var(--white800)
    --fontColour900: var(--white900)

    --glassColour: var(--glassDark)

    --accentPrimary: var(--maroonPrimary)
    --accentSecondary: var(--maroonSecondary)
    --accentThirdary: var(--maroonThirdary)
