@import "../../App.sass"

.snippet
  position: fixed
  z-index: -5
  padding: 4px
  border-radius: 4px
  transform: translate(-50%, -50%)

  @include media("<=tablet")
    display: none

  .nor
    color: #abb2bf
  .kwd
    color: #c678dd
  .bin
    color: #56b6c2
  .def
    color: #61afef
  .com
    color: #5c6370
  .str
    color: #98c379
  .num
    color: #d19a66
  .ins
    color: #e5c07b
  .par
    color: #e06c75
