@import "../App.sass"

.notfound-container
    width: 100%
    height: 100vh
    overflow: hidden

    .bg-container
        position: fixed
        left: 0
        top: 0
        z-index: -10
        width: 100vw
        height: 100vh
        overflow: hidden

        .bg-text
            color: var(--bgColour400)

    .notfound-content
        text-align: center
        width: 35rem
        gap: 16px
        padding: 16px
        border-radius: 8px

        .notfound-button
            background-color: var(--bgColour)
            color: var(--fontColour)
            border: 0.15rem solid var(--accentPrimary)
            padding: 0.4rem 1rem
            border-radius: 8px
            transition: 0.5s

            &:hover
                scale: 1.05

    //     .notfound-or
    //         font-size: 20px
    //         margin: 10px 0px
