@import "../../App.sass"
@import "../constants/Theme"
@import "../constants/Global"

.landing-container
    display: grid
    grid-template-areas: "text-content image title-transition"

    @include media("<=laptop")
        grid-template-areas: "image" "title-transition" "text-content"

    .bg-container
        position: fixed
        left: 0
        top: 0
        z-index: -10
        width: 100vw
        height: 100vh
        overflow: hidden

        .bg-text
            color: var(--bgColour400)

    .dim
        width: 25vw
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center

        @include media("<=laptop")
            width: 80vw

    .glass-bg
        padding: 8px
        border-radius: 8px

    .landing-content
        grid-area: text-content
        display: flex
        align-items: flex-end

        text-align: right

        @include media("<=laptop")
            text-align: left
            align-items: flex-start
            justify-content: flex-start

        .title
            letter-spacing: 8px
            margin-right: -8px

        .description
            line-height: 1.15rem

        .landing-link
            color: var(--accentPrimary)

    .landing-title
        grid-area: title-transition
        display: flex

        @include media("<=laptop")
            text-align: left
            flex-direction: row
            justify-content: flex-start

    .landing-image-container
        grid-area: image
        display: flex
        justify-content: center

        .landing-image
            width: 420px
            margin-bottom: 100px
            // filter: drop-shadow(4px 4px var(--accentPrimary))

            @include media("<=desktop")
                width: 320px
                margin-bottom: 40px
