@import "../constants/Theme"

.extLink
  transition: 0.3s

  &:hover
    cursor: pointer
    color: var(--accentThirdary)

.btn
  width: max-content
  display: flex
  align-items: center
  justify-content: center
  padding: 0.8rem 1rem
  border-radius: 8px
  transition: 0.3s all
  &:hover
    cursor: pointer

.btn-solid
  background-color: var(--accentPrimary)
  &:hover
    // scale: 1.025
    filter: drop-shadow(8px 8px var(--accentThirdary))
  &-text
    color: var(--white500)

.btn-outline
  background-color: var(--bgColour)
  border: 0.1rem solid var(--accentColour)
  &:hover
    background-color: var(--bgColour300)
  &-text
    color: var(--accentColour400)

.btn-ghost
  background-color: none
  &:hover
    background-color: var(--maroon50)
  &-text
    color: var(--accentColour)
