@import "../../../App.sass"

.skills-container
    // background-color: skyblue
    display: flex
    gap: 32px

    @include media("<=tablet")
        flex-direction: column

    .skills
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        gap: 16px

        &-items
            width: 20vw
            display: flex
            justify-content: center
            flex-wrap: wrap
            gap: 12px

            @include media("<=tablet")
                width: 90vw
