@import "../../App"

.scrolldown-container
  position: fixed
  bottom: 0
  left: 50%
  transform: translateX(-50%)
  width: 0px
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 6px
  color: var(--fontColour)
  z-index: 80
  transition: 0.5s
  opacity: 1
  visibility: hidden
  animation-name: float, fadeIn
  animation-duration: 3s, 2s
  animation-iteration-count: infinite, 1
  animation-delay: 2s, 2s
  animation-fill-mode: forwards, forwards

  &:hover
    cursor: pointer
    color: var(--accentThirdary)

  @include media("<=tablet")
    display: none

  .scrolldown-mouse
    width: 19px
    height: 30px
    display: flex
    justify-content: center
    margin-bottom: 2px
    border: 2px solid var(--fontColour)
    border-radius: 9px
    transition: 0.5s
    background-color: var(--bgColour)

    .scrolldown-wheel
      width: 3px
      height: 6px
      margin-top: 6px
      background-color: var(--fontColour)
      border-radius: 5px
      transition: 0.5s

  .scrolldown-arrow
    font-size: 21px

@keyframes float
  0%
    transform: translateY(0px)

  50%
    transform: translateY(-5px)

  100%
    transform: translateY(0px)

@keyframes fadeIn
  0%
    opacity: 0

  100%
    opacity: 1
    visibility: visible
