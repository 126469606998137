@import '../constants/Theme'

.base
  color: var(--fontColour)

.inverse
  color: var(--bgColour)

.accent
  color: var(--accentPrimary)
